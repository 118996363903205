<template>
  <div class="timeline-wrapper">
    <div class="timeline-item" v-for="item in timelineData" :key="item.id">
      <van-row class="timeline-item-row" >
        <van-col span="12" class="left"><span>{{ item.eventTime}}</span></van-col>
        <van-col span="12" class="right">
          <p class="bottom"><router-link :to="{ path: `/timeline/${item.taskId}`}" class="task-link">{{ eventType[item.eventType] }}</router-link></p>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { EventTypeText } from '@/utils/constant';
export default {
  name: 'Timeline',
  props: {
    timelineData: Array,
  },
  data() {
    return {
      eventType: EventTypeText,
    }
  },
}
</script>

<style lang="less">
  @import '@/styles/variables.less';

  .timeline-wrapper {
    margin-top: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
  }

  .timeline-item {
    position: relative;
    padding: 0 18px;
    background: #fff;

    .left {
      position: relative;
      color: #9e9e9e;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
    }

    .right {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 20px;
      border-left: 1px solid #eee;

      &::before {
        position: absolute;
        top: 45%;
        left: -5px;
        display: block;
        width: 8px;
        height: 8px;
        background: #ccc;
        transform: rotateZ(50deg);
        content: "";
      }

      .top,
      .bottom {
        margin: 0;
      }

      .top {
        margin-bottom: 5px;
        color: #333;
      }

      .bottom {
        color: #9e9e9e;
      }

      .task-link {
        color: @active;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
</style>
