<template>
  <!-- <div class="page-equipment-container" v-if="isAuthorized"> -->
  <div class="page-equipment-container">
    <van-tabs v-model="activeTab" sticky>
      <van-tab title="基本信息">
        <div class="swiper-wrapper">
          <van-swipe :autoplay="3000" lazy-render height="104px" v-if="equipmentImages.length > 0">
            <van-swipe-item v-for="image in equipmentImages" :key="image">
              <img :src="image" />
            </van-swipe-item>
          </van-swipe>
          <div v-else class="no-image-wrapper">
            <div class="no-image-inner">
              <iconfont name="iconzanwutupian" class="icon-no-image" />
              <p>暂无图片</p>
            </div>
          </div>
        </div>
        <div class="base-info">
          <van-row class="row-title">
            <van-col span="18" class="col-name van-ellipsis">{{ equipmentInfo.name }}</van-col>
            <van-col span="6" class="col-status" style="text-align: right;">
              <van-tag size="medium" round :color="equipmentStatusColor">{{ equipmentStatus }}</van-tag>
            </van-col>
          </van-row>
          <van-row class="row-manufacturer-name">
            <van-col span="12" class="col-manufacturer-name">
              <span class="caption-item">{{ equipmentInfo.manufacturerName }}</span>
            </van-col>
            <van-col span="12" class="col-model-name">
              <span class="caption-item">{{ equipmentInfo.modelName }}</span>
            </van-col>
          </van-row>
          <van-row class="row-sn">
            <van-col span="12" class="col-sn">
              <span class="caption-item">{{ equipmentInfo.sn }}</span>
            </van-col>
            <van-col span="12" class="col-type-name">
              <span class="caption-item">{{ equipmentInfo.typeName }}</span>
            </van-col>
          </van-row>
        </div>
        <van-cell-group class="cell-group-wrapper">
          <van-cell title="设备标签" value="">
            <slot name="value">
              <van-tag
                type="primary"
                round
                size="medium"
                v-for="item in equipmentTags"
                style="margin-right: 5px;"
                :key="item"
              >{{ item }}</van-tag>
            </slot>
          </van-cell>
        </van-cell-group>
        <van-cell-group class="cell-group-wrapper">
          <van-cell title="设备编号" :value="equipmentInfo.equipmentNo">
            <slot name="value">
              <div class="equipmentno-cell" v-if="!!equipmentInfo.equipmentNo">
                {{ equipmentInfo.equipmentNo }}
                <iconfont
                  name="iconfuzhi"
                  class="icon-copy"
                  @click="handleCopy"
                />
              </div>
            </slot>
          </van-cell>
          <van-cell title="所属科室" :value="equipmentInfo.departmentName" />
          <van-cell title="所在房间" :value="equipmentInfo.roomNo" />
        </van-cell-group>
        <van-cell-group class="cell-group-wrapper">
          <van-cell title="设备在保" :value="warranthyStatus[equipmentInfo.warranthyStatus]" />
          <van-cell title="保修结束日期" :value="equipmentInfo.warranthyEndDate" />
          <van-cell title="保养周期" :value="equipmentInfo.maintainPeriod" />
        </van-cell-group>
        <van-cell-group class="cell-group-wrapper">
          <van-cell title="设备别名" :value="equipmentInfo.alias" />
          <van-cell title="设备品牌" :value="equipmentInfo.brandName" />
        </van-cell-group>
        <van-cell-group class="cell-group-wrapper">
          <van-cell title="签约状态" :value="equipmentInfo.isSigned ? '已签约' : '未签约'" />
          <van-cell title="签约服务商" :value="signedMaintainer" />
        </van-cell-group>
        <van-cell-group class="cell-group-wrapper">
          <van-cell v-for="item in equipmentOtherInfo" :title="item.label" :value="item.value" :key="item.id" />
        </van-cell-group>
        <van-cell-group class="cell-group-wrapper" v-if="equipmentOtherImages.length > 0">
          <h3 class="uploader-title">合格证&注册证</h3>
          <div class="uploader-wrapper">
            <yxk-uploader preview :image-list="equipmentOtherImages"/>
          </div>
        </van-cell-group>
      </van-tab>
      <van-tab title="维保历史">
        <timeline v-if="timelineData.length > 0" :timelineData="timelineData" />
        <van-empty v-else description="暂无数据" />
      </van-tab>
    </van-tabs>
    <div class="btn-wrapper">
      <!-- <van-button
        round
        :color="btnDisabled ? '#ccc' : '#DB1E32'"
        :disabled="btnDisabled"
        class="btn-repair"
        @click="handleRepair">报修</van-button> -->

      <van-button
        round
        :color="'#DB1E32'"
        class="btn-repair"
        @click="handleRepair">报修</van-button>
    </div>
  </div>
  <!-- <van-loading class="loading-bg" size="24px" vertical v-else>加载中...</van-loading> -->
</template>

<script>
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import Uploader from '@/components/uploader/index.vue';
import {
  fetchEquipmentDetail,
  fetchEquipmentTimeline,
  fetchMaintainersByEquipmentId,
} from '@/services/equipment';
import { formatMoney, accMul, copy2Clipboard } from '@/utils/index';
import {
  AttachmentCategory,
  ResourcePath,
  WarranthyStatusText,
  EquipmentSourceTypeText,
  EquipmentStatusText,
  EquipmentPurchaseMethodText
} from '@/utils/constant';
import Timeline from './components/timeline.vue';
export default {
  name: 'Equipment',
  data() {
    return {
      activeTab: 0,
      equipmentInfo: {},
      timelineData: [],
      equipmentImages: [],
      equipmentOtherImages: [],
      equipmentTags: [],
      equipmentInquiries: [],
      warranthyStatus: WarranthyStatusText,
      equipmentSourceType: EquipmentSourceTypeText,
      equipmentStatus: '',
      equipmentStatusColor: '#DB1E32',
      equipmentOtherInfo: [],
      signedMaintainer: '--',
      isInit: false,
    }
  },
  computed: {
    btnDisabled() {
      return !(this.signedMaintainer !== '--' && !!this.routesMap.goEquipmentRepairListPage);
    },
    isAuthorized() {
      return !!this.$store.getters.openId;
    },
    ...mapGetters([
      'equipmentId',
      'equipmentName',
      'equipmentManufacturerName',
      'equipmentModelName',
      'equipmentSn',
      'equipmentNo',
      'routesMap'
    ]),
  },
  methods: {
    async loadEquipmentDetail() {
      try {
        const res = await fetchEquipmentDetail(this.equipmentId);
        if (res.code === 0) {
          if (res.data !== null) {
            this.$store.commit('SET_EQUIPMENT_INFO', res.data.equipment);
            this.equipmentTags = res.data.tags;
            this.equipmentInfo = res.data.equipment;
            this.equipmentStatus = EquipmentStatusText[res.data.equipment.status].label;
            this.equipmentStatusColor = EquipmentStatusText[res.data.equipment.status].color;
            this.equipmentImages = res.data.attachments
              .filter(item => item.category === AttachmentCategory.EQUIPMENT_PHOTO)
              .map(item => `${ResourcePath}${item.res}`);
            this.equipmentInquiries = res.data.inquiries ?? [];
            this.equipmentOtherImages = res.data.attachments
              .filter(item =>
                item.category === AttachmentCategory.EQUIPMENT_CERTIFICATION ||
                item.category === AttachmentCategory.EQUIPMENT_REGISTRATION
              )
              .map(item => ({ localId: `${ResourcePath}${item.res}`, res: item.res }));
            this.equipmentOtherInfo = this.getEquipmentSourceType();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    // 查询维保历史
    async loadEquipmentTimeline() {
      try {
        const res = await fetchEquipmentTimeline(this.equipmentId);
        if (res.code === 0) {
          this.timelineData = res.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },

    // 查询签约服务商
    async loadSignedMaintainer() {
      try {
        const res = await fetchMaintainersByEquipmentId(this.equipmentId);
        if (res.code === 0) {
          if (res.data && res.data.length) {
            this.signedMaintainer = res.data[0].name;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    getEquipmentSourceType() {
      const isPurchaseMethod = this.equipmentInfo.purchaseMethod === null;
      const obtainedBy = this.equipmentInfo.obtainedBy ? this.equipmentSourceType[this.equipmentInfo.obtainedBy] : '--';
      const netWorth = this.equipmentInfo.netWorth ? `¥ ${this.equipmentInfo.netWorth}` : '';
      const usefulAge = this.equipmentInfo.usefulAge ? `${this.equipmentInfo.usefulAge} 年` : '';
      const originWorth = this.equipmentInfo.originWorth ? `¥ ${this.equipmentInfo.originWorth}` : '';
      const depreciationRate = this.equipmentInfo.depreciationRate ? `${accMul(this.equipmentInfo.depreciationRate, 100)}%` : '';
      const purchaseMethodData = [];
      if (!isPurchaseMethod) {
        if (this.equipmentInfo.purchaseMethod !== 'SINGLE') {
          if (this.equipmentInquiries) {
            this.equipmentInquiries.slice(0, 2).forEach((item, index) => {
              const _price = `¥ ${item.price == null ? '--' : formatMoney(item.price, 2)}`;
              if (index === 0) {
                purchaseMethodData.push(
                  {
                    id: 12,
                    label: '询价供应商二',
                    value: `${item.company ?? '--'}`
                  },
                );
                purchaseMethodData.push(
                  { id: 13, label: '报价', value: _price },
                );
                purchaseMethodData.push(
                  {
                    id: 14,
                    label: '电话',
                    value: `${item.contactTel ?? '--'}`
                  },
                );
              } else {
                purchaseMethodData.push(
                  {
                    id: 15,
                    label: '询价供应商三',
                    value: `${item.company ?? '--'}`
                  },
                );
                purchaseMethodData.push(
                  { id: 16, label: '报价', value: _price },
                );
                purchaseMethodData.push(
                  {
                    id: 17,
                    label: '电话',
                    value: `${item.contactTel ?? '--'}`
                  },
                );
              }
            });
          }
        }
      }
      const defaultSource = [
        { id: 0, label: '首次启用日期', value: this.equipmentInfo.initialUseDate ?? '' },
        { id: 1, label: '设备使用年限', value: usefulAge },
        { id: 2, label: '年折旧率', value: depreciationRate },
        { id: 3, label: '生产日期', value: this.equipmentInfo.productionDate ?? '' },
        {
          id: 4,
          label: '设备来源',
          value: obtainedBy
        },
      ]
      const sourceTypeMap = {
        PURCHASED: [
          { id: 0, label: '首次启用日期', value: this.equipmentInfo.initialUseDate ?? '' },
          { id: 1, label: '设备使用年限', value: usefulAge },
          { id: 2, label: '年折旧率', value: depreciationRate },
          { id: 3, label: '折旧后价值', value: netWorth },
          { id: 4, label: '生产日期', value: this.equipmentInfo.productionDate ?? '' },
          {
            id: 5,
            label: '设备来源',
            value: obtainedBy
          },
          {
            id: 6,
            label: '购买方式',
            value:
                `${isPurchaseMethod ? '' : EquipmentPurchaseMethodText[this.equipmentInfo.purchaseMethod]}`
          },
          {
            id: 7,
            label: '成交供应商',
            value: `${this.equipmentInfo.obtainedFrom ?? '--'}`
          },
          { id: 8, label: '购买日期', value: this.equipmentInfo.obtainedDate ?? '' },
          { id: 9, label: '购买金额', value: originWorth },
          {
            id: 10,
            label: '联系人',
            value: `${this.equipmentInfo.srcContactPerson ?? '--'}`
          },
          {
            id: 11,
            label: '联系电话',
            value: `${this.equipmentInfo.srcContactTel ?? '--'}`
          },
          ...purchaseMethodData,
        ],
        GIFT: [
          ...defaultSource,
          { id: 5, label: '馈赠日期', value: this.equipmentInfo.obtainedDate ?? '' },
          { id: 6, label: '原始价值', value: originWorth },
          {
            id: 7,
            label: '馈赠单位',
            value: `${this.equipmentInfo.obtainedFrom ?? '--'}`
          },
          { id: 8, label: '折旧后价值', value: netWorth },
        ],
        BORROWED: [
          ...defaultSource,
          { id: 5, label: '借调日期', value: this.equipmentInfo.obtainedDate ?? '' },
          { id: 6, label: '原始价值', value: originWorth },
          {
            id: 7,
            label: '设备原单位',
            value: `${this.equipmentInfo.obtainedFrom ?? '--'}`
          },
          { id: 8, label: '折旧后价值', value: netWorth },
        ],
        ALLOTTED: [
          ...defaultSource,
          { id: 5, label: '调拨日期', value: this.equipmentInfo.obtainedDate },
          { id: 6, label: '原始价值', value: originWorth },
          {
            id: 7,
            label: '调拨单位',
            value: `${this.equipmentInfo.obtainedFrom ?? '--'}`
          },
          { id: 8, label: '折旧后价值', value: netWorth },
        ],
        default: defaultSource,
      };

      return sourceTypeMap[this.equipmentInfo.obtainedBy];
    },

    handleRepair() {
      this.isInit = false;
      this.$router.push(`/repair/edit?equipmentId=${this.equipmentId}`);
    },

    handleCopy() {
      copy2Clipboard(this.equipmentInfo.equipmentNo);
      Toast('已复制到粘贴板');
    },

    async init() {
      this.$store.dispatch('showLoading');
      try {
        await Promise.all([this.loadEquipmentDetail(), this.loadEquipmentTimeline(), this.loadSignedMaintainer()]);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch('hideLoading');
      }
    }
  },
  mounted() {
    if (!this.equipmentId) {
      return;
    }
    this.isInit = true;
    this.init();
  },
  watch: {
    equipmentId(newId) {
      if (!this.isInit && newId) {
        this.init();
        this.isInit = true;
      }
    }
  },
  components: {
    Timeline,
    yxkUploader: Uploader,
  }
}
</script>

<style lang="less">
  .page-equipment-container {
    min-height: 100vh;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 100px;

    .btn-wrapper {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      background: #fff;
      box-shadow: 0 -1px 5px #eee;

      .btn-repair {
        width: 100px;
      }
    }

    .caption-item {
      position: relative;
      padding-left: 5px;

      &::before {
        position: absolute;
        top: 50%;
        left: -5px;
        display: block;
        width: 5px;
        height: 5px;
        background: #686868;
        border-radius: 100%;
        transform: translateY(-50%);
        content: "";
      }
    }

    .row-title {
      margin-bottom: 10px;
    }

    .row-manufacturer-name {
      margin-bottom: 5px;
    }

    .col-name {
      font-weight: 600;
      font-size: 18px;
      text-align: left;
    }

    .col-status {
      align-self: center;
    }

    .col-manufacturer-name,
    .col-model-name,
    .col-type-name,
    .col-sn {
      font-size: 12px;
      text-align: left;
    }
  }

  .swiper-wrapper {
    width: 355px;
    height: 200px;
    margin: 10px auto;
    overflow: hidden;
    border-radius: 5px;
    .no-image-wrapper {
      position: relative;
      height: 200px;
      text-align: center;
      .no-image-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        .icon-no-image {
          width: 30px;
          height: 30px;
        }
      }
    }

    img {
      width: 100%;
      height: 254px;
    }
  }

  .base-info {
    width: 355px;
    margin: 10px auto;
    padding: 16px;
    background: #fff;
  }

  .cell-group-wrapper {
    width: 355px;
    margin: 10px auto;
  }

  .uploader-title {
    padding: 10px;
    margin-bottom: 0;
  }
  .uploader-wrapper {
    padding: 5px;
  }

  .equipmentno-cell {
    .icon-copy {
      transform: translateY(2px);
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
  }
  .loading-bg {
    height: 100vh;
    .van-loading__spinner {
      margin-top: 200px;
    }
  }
</style>
